.calendar-not-active {
    background: var(--dark-blue) !important;
    width: 75%;
    padding-left: 1% !important;
    padding-right: 1% !important;
    /*margin: 52px 154px 54px 42px;*/
    /*padding: 9px 0.1px 9px 29px;*/
}

.calendar-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ece7ee !important;
    width: 75%;
    padding-left: 1% !important;
    padding-right: 1% !important;
    /*margin: 52px 154px 54px 42px;*/
    /*padding: 9px 0.1px 9px 29px;*/
}


.swiper-button-prev {
    background-color: transparent !important;
    color: #fff !important;
    fill: black !important;
    stroke: black !important;
    margin-left: -10px !important;
    font-size: 12px !important;
}



.swiper-button-next{
    background-color: transparent !important;
    color: #fff !important;
    fill: black !important;
    stroke: black !important;
    margin-right: -10px !important;
}

.swiper-button-prev::after{
    font-size: 12px !important;
    font-weight: 900 !important;
}

.swiper-button-next::after{
    font-size: 12px !important;
    font-weight: 900 !important;
}

.calendar-container {
    width: 100% !important;
    /*height: 98px !important;*/
    /*margin: 52.2px 5px 54.2px 52.6px !important;*/
    /*padding: 2.8px 40px 9.8px 40.4px !important;*/
    /*padding-top: 1% !important;*/
    /*padding-bottom: 1% !important;*/
    padding-left: 1% !important;
    padding-right: 1% !important;
    opacity: 1 !important;
    border-radius: 36px !important;
    border: solid 1.5px #ece7ee !important;
}

