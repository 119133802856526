:root {
    --red: #e94e1b;
    --teal: #18B4B3;
    --topaz: rgba(24, 180, 179, 0.2);
    --white: #ffffff;
    --light-white: rgb(243,241,247);
    --medium-white: #ece7ee;
    --light-medium-gray: #b1b1b1;
    --light-gray: #9B9CA3;
    --team-card-grey: #f3f1f7;
    --medium-light-gray: #87868F;
    --light-black: #110F22;
    --black: #000000;
    --dark-blue: #0E0C1B;
    --light-blue: #1D1A3B;
    --week-open-yellow: #ffdd07;
    --winning-green: #0fb958;
    --losing-red: #dc1422;
    --group-a: #00A75D;
    --group-b: #004F9F;
    --group-c: #E30613;
    --group-d: #F9B000;
    --group-e: #6298B7;
    --group-f: #32333B;
    --border-first: #009245;
    --border-last: #CB0028;
    --color-golden: #E1AC39;

}
