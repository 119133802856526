@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Syne:wght@400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css?family=Syne:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic|Open Sans:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic|Cabin:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic|Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic|Roboto:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic&display=auto&ver=6.3.1");
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Syne:wght@400;500;600;700;800&display=swap');
@import url("https://fontsforyou.com/fonts/d/Dokyo.ttf");
@import url("https://use.typekit.net/meq5uek.css");
@import "variables.css";
@import "fonts.css";

.gibson {
    font-family: 'canada-type-gibson', sans-serif;
}

.bebas {
    font-family: 'bebas-neue-by-fontfabric', sans-serif;
}

.cabin {
    font-family: 'Cabin', sans-serif;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

.syne {
    font-family: 'Syne', sans-serif;
}

.dokyo {
    font-family: 'Dokyo', sans-serif;
}

.open-sans {
    font-family: 'Open Sans', sans-serif !important;
}

.sfProDisplay {
    font-family: 'SF Pro Display', sans-serif !important;
}

.sfProDisplayBold {
    font-family: 'SF Pro Display Bold', sans-serif !important;
}

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    background-color: var(--dark-blue) !important;
}

.bg-medium-white {
    background-color: var(--medium-white);
}

.border-medium-white {
    border: var(--bs-border-width) var(--bs-border-style) var(--medium-white) !important;
}

.w-fit {
    width: fit-content !important;
}

.skins-hr {
    color: var(--light-gray) !important;
    height: 5px !important;
}

.skins-footer-hr {
    color: var(--light-gray) !important;
    height: 10px !important;
}

.invalid-tooltip {
    position: relative !important;
}

.rounded-top-home-section-4 {
    border-top-left-radius: 84px !important;
    border-top-right-radius: 84px !important;
}

.rounded-my-skins {
    border-radius: 45px !important;
}

.rounded-my-skins-top {
    border-top-left-radius: 45px !important;
    border-top-right-radius: 45px !important;
}

.rounded-my-skins-bottom {
    border-bottom-left-radius: 45px !important;
    border-bottom-right-radius: 45px !important;
}

.bg-dark-blue {
    background-color: var(--dark-blue) !important;
}
.bg-light-blue {
    background-color: var(--light-blue) !important;
}

.bg-skins-topaz {
    background-color: var(--topaz) !important;
}

.bg-skins-teal {
    background-color: var(--teal) !important;
}

.bg-skins-red {
    background-color: var(--red) !important;
}

.bg-skins-white {
    background-color: var(--light-white) !important;
}

.bg-skins-winning {
    background-color: var(--winning-green) !important;
}

.bg-skins-losing {
    background-color: var(--losing-red) !important;
}

.bg-group-a{
    background-color: var(--group-a) !important;
}
.bg-group-b{
    background-color: var(--group-b) !important;
}
.bg-group-c{
    background-color: var(--group-c) !important;
}
.bg-group-d{
    background-color: var(--group-d) !important;
}
.bg-group-e{
    background-color: var(--group-e) !important;
}
.bg-group-f{
    background-color: var(--group-f) !important;
}

.bg-grey {
    background-color: var(--bs-gray) !important;
}

.bg-green {
    background-color: var(--winning-green);
}
.bg-team-card-grey {
    background-color: var(--team-card-grey) !important;
}

.border-white {
    border: 3px solid white;
}
.border-first-place {
    border: 3px solid var(--border-first);
}
.border-last-place {
    border: 3px solid var(--border-last);
}


.navbar-bg {
    background-color: rgba(14, 12, 27, 0.9) !important;
    /* backdrop-filter: blur(10px) !important; */
}

.nav-item {
    font-size: 16px !important;
    font-family: 'Cabin', sans-serif;
    font-weight: 600 !important;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
    border-bottom-color: var(--red) !important;
}

.footer-links {
    color: #FFFFFF;
    text-decoration: none;
    transition: all .2s linear;
}
.footer-links:hover, .footer-links:active, .footer-links:focus {
    color: var(--teal) !important;
    text-decoration: none;
    transition: all .2s linear;
}

.home-section-1-bg {
    background-image: url("../images/home/section_1_bg.png");
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
}

.home-section-2-bg {
    background-image: url("../images/wp-content/uploads/2023/03/Component-250-1.png");
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
}



  .react-player-video-wrapper {
    object-fit: fill; /* Ensures the video covers the entire container */
    
}

  

.footer-bg {
    background-image: url("../images/wp-content/uploads/2021/10/2nd-Section-Transparent-Shape.png");
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: -178px 411px;
}

.heading-home-skins {
    font-size: 130px;
}
.heading-home-skins-mobile {
    font-size: 50px;
}
.register-desktop{
    background-color: white;
    border-radius: 20px;
    box-shadow: inset 0 0 10px #000000;
}
.home-section-3-lg-item {
    border: none !important;
    color: var(--light-gray) !important;
    font-weight: 500 !important;
    background-color: transparent !important;
    border-left: 12px solid transparent !important;
    border-bottom: 1px solid #CCCCCC !important;
    animation: ease-in-out;
}

.home-section-3-lg-item img {
    opacity: 0.4 !important;
}

.home-section-3-lg-item.active {
    border-left: 12px solid var(--red) !important;
    color: var(--light-black) !important;
    font-weight: 500 !important;
    background-color: transparent !important;
    background-image: radial-gradient(at center left, #E7E9E9 34%, #E7E9E91F 100%);
    border-bottom: none !important;
    animation: ease-in-out;
    padding-top: 1rem !important;
}

.home-section-3-lg-item:active, .home-section-3-lg-item:hover {
    border-left: 12px solid var(--red) !important;
    font-weight: 500 !important;
    background-color: transparent !important;
    background-image: radial-gradient(at center left, #E7E9E9 34%, #E7E9E91F 100%);
    animation: ease-in-out;
}

.home-section-3-lg-item.active img {
    opacity: 1 !important;
}

.icon {
    position: relative;
    margin-top: 75px;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 20px;
    cursor: pointer;
    display: inline-flex;
}

.next-arrow {
    position: absolute;
    top: 25px;
    width: 90%;
    height: 5px;
    background-color: var(--light-gray);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    animation: next-arrow 700ms linear infinite;
    margin-left: 45px;
}

.next-arrow::after, .next-arrow::before {
    content: '';
    position: absolute;
    width: 60%;
    height: 5px;
    right: -12px;
    background-color: var(--light-gray);
}

.next-arrow::after {
    top: -14px;
    transform: rotate(45deg);
}

.next-arrow::before {
    top: 14px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(-45deg);
}

.prev-arrow {
    position: absolute;
    top: 25px;
    width: 90%;
    height: 5px;
    background-color: var(--light-gray);
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    animation: prev-arrow 700ms linear infinite;
}

.prev-arrow::after, .prev-arrow::before {
    content: '';
    position: absolute;
    width: 60%;
    height: 5px;
    left: -12px;
    background-color: var(--light-gray);
}

.prev-arrow::after {
    top: -14px;
    transform: rotate(135deg);
}

.prev-arrow::before {
    top: 14px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(-135deg);
}

.skins-link {
    color: var(--white) !important;
    text-decoration: none !important;
}

.skins-link:hover {
    color: var(--teal) !important;
    text-decoration: none !important;
    transition: all .2s linear;
}

.skins-link-red {
    color: var(--red) !important;
    text-decoration: underline !important;
}

.skins-link-red:hover {
    color: var(--teal) !important;
    text-decoration: underline !important;
    transition: all .2s linear;
}

.contact-form button, .contact-form button:hover, .contact-form button:active, .contact-form button:focus {
    font-family: 'Cabin', sans-serif;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1.3px;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--red);
    border-color: var(--red);
    outline-color: var(--red);
    padding: 20px 40px;
}

.btn-skins-red, .btn-skins-red:hover, .btn-skins-red:active, .btn-skins-red:focus {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--red) !important;
    border-color: var(--red) !important;
    outline-color: var(--red) !important;
    box-shadow: none !important;
    padding: 10px 15px;
}
.btn-skins-blue, .btn-skins-blue:hover, .btn-skins-blue:active, .btn-skins-blue:focus {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--dark-blue) !important;
    border-color: var(--dark-blue) !important;
    outline-color: var(--dark-blue) !important;
    box-shadow: none !important;
    padding: 10px 15px;
}
.bg-modal-header {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    /*border-radius: 5px 5px 0 0;*/
    background-color: var(--dark-blue) !important;
    border-color: var(--dark-blue) !important;
    outline-color: var(--dark-blue) !important;
    box-shadow: none !important;
    /*padding: 10px 15px;*/
    /*color: white;*/
}
.bg-modal-header-yellow {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    /*border-radius: 5px 5px 0 0;*/
    background-color: var(--week-open-yellow) !important;
}


.btn-skins-confirm, .btn-skins-confirm:hover, .btn-skins-confirm:active, .btn-skins-confirm:focus {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--black) !important;
    border-color: var(--black) !important;
    outline-color: var(--black) !important;
    box-shadow: none !important;
    padding: 10px 15px;
}

.btn-pick, .btn-pick:hover, .btn-pick:active, .btn-pick:focus {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--white) !important;
    border-color: var(--white) !important;
    outline-color: var(--white) !important;
    box-shadow: none !important;
    padding: 10px 15px;
}

.btn-pick-selected, .btn-pick-selected:hover, .btn-pick-selected:active, .btn-pick-selected:focus {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.9px;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--red) !important;
    border-color: var(--red) !important;
    outline-color: var(--red) !important;
    box-shadow: none !important;
    padding: 10px 15px;
}

.contact-form .form-control {
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.3px;
    border-radius: 12px 12px 12px 12px;
    background-color: #FFFFFF03;
    border-color: #ffffff36 !important;
    outline-color: #ffffff36 !important;
    padding: 19px 32px;
    color: var(--white) !important;
}

.contact-form .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Cabin', sans-serif;
    color: #fff;
    opacity: 1; /* Firefox */
}

.contact-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Cabin', sans-serif;
    color: #fff;
}

.contact-form .form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Cabin', sans-serif;
    color: #fff;
}

.login-form .form-control {
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.3px;
    border-radius: 12px 12px 12px 12px;
    background-color: #FFFFFF;
    /*border-color: #ffffff36 !important;*/
    /*outline-color: #ffffff36 !important;*/
    padding: 19px 32px;
    color: var(--black) !important;
}

.login-form .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Cabin', sans-serif;
    color: #000;
    opacity: 1; /* Firefox */
}

.login-form .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Cabin', sans-serif;
    color: #000;
}

.login-form .form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Cabin', sans-serif;
    color: #000;
}

.redTemp {
    filter: invert(8%) sepia(94%) saturate(4590%) hue-rotate(358deg) brightness(101%) contrast(112%);
}

.game-rules-ol, .game-rules-ol ol {
    /*counter-reset: item;*/
    padding-left: 10px;
}

.game-rules-ol li {
    display: block;
    font-size: 19px !important;
    font-family: 'Cabin', sans-serif !important;
    color: var(--black) !important;
    font-weight: 500 !important;
    margin-top: 10px !important;
}

.game-rules-ol li:before {
    content: attr(data-level);
    color: var(--red) !important;
    font-weight: 800 !important;
    text-decoration: underline !important;
    margin-right: 15px !important;
}

.nav-tabs {
    border: none !important;
}

.nav-tabs > .nav-item > .nav-link {
    background: none !important;
    border: none !important;
    color: #FFFFFF !important;
}

.nav-tabs > .nav-item > .nav-link.active {
    background: none !important;
    border: none !important;
    color: #FFFFFF !important;
}

.nav-tabs > .nav-item > .nav-link.active > .nav-span {
    background: none !important;
    border: none !important;
    border-bottom: 5px solid var(--red) !important;
    color: #FFFFFF !important;
    padding-bottom: 6px !important;
}

.tab-content {
    background-color: #FFFFFF;
    border-top-left-radius: 45px !important;
    border-top-right-radius: 45px !important;
    margin-top: -23px !important;
    /*padding: 15px;*/
}

.w-60 {
  width: 60% !important;
}

.left-image-payment{
    /*height: 462px;*/
    /*height: 100%;*/
    /*width: 200px;*/
    border-radius: 20px;
}
.payment-card-vertical-line {
    /*height: 462px;*/
    /*padding-left: 2px;*/
    /*padding-right: 2px;*/
    border-left: 3px solid var(--red);
    /*height: 100%;*/
}
.vertical-line-grey{
    height: 45px;
    border-right: 1px solid #707070;
}
.payment-input{
    height: 45px;
    border: 1px solid black;
    box-shadow: 1px 1px 1px 1px #000000;
    /*padding: 3px;*/

}
.skins-red-horizontal-line {
    /*height: 462px;*/
    border-bottom: 3px solid var(--red);
    /*height: 100%;*/
}
.skins-red-horizontal-line-tabs {
    /*height: 462px;*/
    border-bottom: 7px solid var(--red);
    /*height: 100%;*/
}
.skins-grey-horizontal-line-tabs {
    /*height: 462px;*/
    border-bottom: 7px solid #7E7E7F;
    /*height: 100%;*/
}
.skins-transparent-horizontal-line-tabs {
    /*height: 462px;*/
    border-bottom: 7px solid transparent;
    /*height: 100%;*/
}
.skins-grey-horizontal-line {
    /*height: 462px;*/
    border-bottom: 3px solid #7E7E7F;
    /*height: 100%;*/
}

.skins-border-white {
    border: 2px solid white;
}
.input-select-yellow{
    background-color: transparent;
    border: 2px solid var(--week-open-yellow);
    color: var(--week-open-yellow) !important;
    border-radius: 0;
}
.input-select-yellow:focus{
    border: 2px solid var(--week-open-yellow);
    box-shadow: none;
}
.input-select-gold{
    background-color: transparent;
    border: 2px solid var(--color-golden);
    color: var(--color-golden) !important;
    border-radius: 0;
}
.input-select-gold:focus{
    border: 2px solid var(--color-golden);
    box-shadow: none;
}

.input-form-control-yellow{
    background-color: transparent !important;
    border: 2px solid var(--week-open-yellow);
    color: var(--week-open-yellow) !important;
    border-radius: 0;
}
.input-form-control-gold{
    background-color: transparent !important;
    border: 2px solid var(--color-golden);
    color: var(--color-golden) !important;
    border-radius: 0;
}

.input-form-control-yellow:focus{
    border: 2px solid var(--week-open-yellow);
    box-shadow: none;
}
.input-form-control-gold:focus{
    border: 2px solid var(--color-golden);
    box-shadow: none;
}
.input-form-control-yellow::placeholder{
    color: var(--week-open-yellow);
    background-color: transparent;
    /*box-shadow: none;*/
}
.input-form-control-gold::placeholder{
    color: var(--color-golden);
    background-color: transparent;
    /*box-shadow: none;*/
}

.input-select-yellow option {
    color: black !important;
    border-bottom: 1px solid black !important;
}

.input-select-yellow option:first-child {
    background-color: yellow;
    color: black;
}

.avatar {
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
}

.border-bottom-colors {
    border-style: solid !important;
    border-bottom-width: 4px !important;
    border-width: 0 !important;
    background: linear-gradient(to right,
    #F9B000 0%,
    #F9B000 16.67%,
    #004F9F 16.67%,
    #004F9F 33.33%,
    #E30613 33.33%,
    #E30613 50%,
    #121928 50%,
    #121928 66.67%,
    #00A75D 66.67%,
    #00A75D 83.33%,
    #F05D43 83.33%,
    #F05D43 100%);
    background-size: 100% 100%;
    height: 4px !important;
}
.border-bottom-colors-laptop {
    border-style: solid !important;
    border-bottom-width: 7px !important;
    border-width: 0 !important;
    background: linear-gradient(to right,
    #F9B000 0%,
    #F9B000 16.67%,
    #004F9F 16.67%,
    #004F9F 33.33%,
    #E30613 33.33%,
    #E30613 50%,
    #121928 50%,
    #121928 66.67%,
    #00A75D 66.67%,
    #00A75D 83.33%,
    #F05D43 83.33%,
    #F05D43 100%);
    background-size: 100% 100%;
    height: 7px !important;
}
.border-bottom-teal{
    border-bottom: 2px solid var(--teal) !important;
}
.team-card-image{
    height: 34px;
    width: 34px;
    object-fit: fill;
    box-shadow: 0px 2px 2px 0px #00000040;
    /*border-radius: 50%;*/
    /*border: 1px solid #00000040;*/
}

.predict-card{
    /*box-shadow: 0px 10px 4px 0px #00000040;*/
    box-shadow: 1px 2px 1px 3px #00000040;

}
.vertical-line-tabs-teal{
    height: 20px;
    border-left: 2px solid var(--teal);
}
.avatar-border-skins-grey{
    border-radius: 50%;
    border: 3px solid var(--medium-light-gray);
}
.avatar-border-skins-golden{
    border-radius: 50%;
    border: 3px solid var(--color-golden);
}
.error-container{
    min-height: 1.5em;
}
@import "responsive/stylesLaptop.css" screen and (max-width: 1024px);
@import "responsive/stylesTablet.css" screen and (max-width: 768px);
@import "responsive/stylesMobile.css" screen and (max-width: 600px);
