@font-face {
    font-family: "SF Pro Display Bold";
    src: url("../fonts/SFProDisplay-Bold.eot");
    src: url("../fonts/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Bold.woff") format("woff"),
    url("../fonts/SFProDisplay-Bold.svg#SF Pro Display") format("svg");
}

@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay-Regular.eot");
    src: url("../fonts/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff"),
    url("../fonts/SFProDisplay-Regular.svg#SF Pro Display") format("svg");
}

.text-skins-red {
    color: var(--red) !important;
}

.text-skins-teal {
    color: var(--teal) !important;
}

.text-skins-yellow {
    color: var(--week-open-yellow) !important;
}

.text-skins-medium-light-grey {
    color: var(--medium-light-gray) !important;
}

.text-skins-light-medium-gray {
    color: var(--light-medium-gray) !important;
}

.text-winning {
    color: var(--winning-green) !important;
}

.text-losing {
    color: var(--losing-red) !important;
}

.text-light-black
{
    color: var(--light-black) !important;
}
.text-skins-gold
{
    color: var(--color-golden) !important;
}

.text-transparent
{
    color: transparent !important;
}


.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}
