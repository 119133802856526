@media only screen and (max-width: 600px) {

    .heading-home-skins {
        font-size: 50px !important;
    }

    .home-section-1-bg {
        background-image: url("../../images/home/mobile/section_1/Component 251 – 1.webp") !important;
        background-size: cover;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .home-section-2-bg {
        background-image: url("../../images/home/mobile/section_2/Component 250 – 1.webp") !important;
        background-size: cover;
        object-fit: cover;
        background-repeat: no-repeat;
    }

    .btn-skins-red, .btn-skins-red:hover, .btn-skins-red:active, .btn-skins-red:focus {
        font-family: 'Roboto', sans-serif;
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 0.9px;
        border-radius: 12px 12px 12px 12px;
        background-color: var(--red) !important;
        border-color: var(--red) !important;
        outline-color: var(--red) !important;
        box-shadow: none !important;
        padding: 10px 25px !important;
    }


    .calendar-container {
        width: 100% !important;
        height: 74px !important;
        /*margin: 52.2px 5px 54.2px 52.6px !important;*/
        /*padding: 2.8px 40px 9.8px 40.4px !important;*/
        /*padding-top: 1% !important;*/
        /*padding-bottom: 1% !important;*/
        padding-left: 1% !important;
        padding-right: 1% !important;
        opacity: 1 !important;
        border-radius: 36px !important;
        border: none !important;
    }

    .calendar-not-active {
        background: transparent !important;
        width: 75%;
        padding-left: 1% !important;
        padding-right: 1% !important;
        /*margin: 52px 154px 54px 42px;*/
        /*padding: 9px 0.1px 9px 29px;*/
    }

    .calendar-active {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent !important;
        width: 75%;
        padding-left: 1% !important;
        padding-right: 1% !important;
        /*margin: 52px 154px 54px 42px;*/
        /*padding: 9px 0.1px 9px 29px;*/
    }

    .swiper-button-prev {
        background-color: transparent !important;
        color: #000000 !important;
        fill: black !important;
        stroke: black !important;
        margin-left: -10px !important;
        font-size: 12px !important;
    }


    .swiper-button-next {
        background-color: transparent !important;
        color: #000000 !important;
        fill: black !important;
        stroke: black !important;
        margin-right: -10px !important;
    }


    .btn-pick, .btn-pick:hover, .btn-pick:active, .btn-pick:focus {
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        letter-spacing: 0.9px;
        border-radius: 25px !important;
        background-color: var(--white) !important;
        border-color: var(--white) !important;
        outline-color: var(--white) !important;
        box-shadow: none !important;
        padding: 5px 25px !important;
    }

    .btn-pick-selected, .btn-pick-selected:hover, .btn-pick-selected:active, .btn-pick-selected:focus {
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.9px;
        border-radius: 25px !important;
        background-color: var(--red) !important;
        border-color: var(--red) !important;
        outline-color: var(--red) !important;
        box-shadow: none !important;
        padding: 5px 15px !important;
    }
    .team-card-image{
        height: 20px !important;
        width: 20px !important;
        object-fit: fill;
        box-shadow: 0px 2px 2px 0px #00000040;
        /*border-radius: 50%;*/
        /*border: 1px solid #00000040;*/
    }
}
